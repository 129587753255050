import { cardPatterns, defaultSpaces } from "./constant";

//card number==============================================================================

export function getCardBrand(cardNumber) {
    for (let card of cardPatterns) {
        {
            const re = new RegExp(card.pattern);
            if (cardNumber.match(re) != null)
                return card.brand;
        }
    }
}



export function spaceSeparator(e) {
    const cardBrand = getCardBrand(e.target.value);

    const cardData = cardPatterns.find(card => card.brand === cardBrand)

    if (e.keyCode !== 8) {
        const inpLen = e.target.value.length;
        let spaces = defaultSpaces;

        if (cardData && cardData.spaces) {
            spaces = cardData.spaces
        }

        if (spaces.includes(inpLen)) {
            e.target.value += ' ';
        }



    }
}

/**
 * 
 * @param {*} val 
 * @returns 
 */

export function luhnCheck(cardNumber) {
    cardNumber = cardNumber.split(' ').join("");
    if (parseInt(cardNumber) <= 0 || (!/\d{15,16}(~\W[a-zA-Z])*$/.test(cardNumber)) || cardNumber.length > 16) {
        return false;
    }
    var carray = new Array();
    for (var i = 0; i < cardNumber.length; i++) {
        carray[carray.length] = cardNumber.charCodeAt(i) - 48;
    }
    carray.reverse();
    var sum = 0;
    for (var i = 0; i < carray.length; i++) {
        var tmp = carray[i];
        if ((i % 2) != 0) {
            tmp *= 2;
            if (tmp > 9) {
                tmp -= 9;
            }
        }
        sum += tmp;
    }
    return ((sum % 10) == 0);
}

/**
 * 
 * @param {*} number 
 * @returns 
 */
export function validateCardNumber(number) {

    return luhnCheck(number);
}

// name on card

//check string has number
export function hasNumber(myString) {
    return /\d/.test(myString);
}

/**
 * 
 * @param {*} name 
 * @returns 
 */

export function checkCardHolderName(name) {
    if (name.length <= 0) {
        return false
    }
    if (hasNumber(name)) {
        return false
    }

    return true


}


//card exp response======================================================================
/**
 * 
 * @returns bool
 */
export const checkCardExp = (formNode) => {
    var today, someday;
    var exMonth = +formNode.querySelector(".cardExpMonth").value;
    var exYear = +formNode.querySelector(".cardExpYear").value;
    today = new Date();
    someday = new Date();
    someday.setFullYear(exYear, exMonth, 1);
    if (someday < today) {
        return false;
    }

    return true
}




//card cvv======================================================================
//check string is only number
export function isNumeric(value) {
    return /^\d+$/.test(value);
}


//allow number only in text input
export function validateNumInput(evt, len) {
    const theEvent = evt || window.event;


    // Handle key press
    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);

    const isNan = isNaN(+key);
    if (isNan) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();

    }
    const updatedInp = evt.target.value.split(' ').join('');

    if (len && updatedInp.length > len - 1) {
        theEvent.preventDefault()
    }
}

/**
 * 
 * @param {*} e 
 * @param {*} len 
 */
export function validateCardLen(e) {
    const cardBrand = getCardBrand(e.target.value);
    const cardData = cardPatterns.find(card => card.brand === cardBrand)
    let len = 16;
    if (cardData?.length) {
        len = cardData.length
    }

    validateNumInput(e, len)
}



export function isValidCVV(cvv, cardBrand) {
    if (!isNumeric(cvv)) {
        return false;
    }
    let valid = false;

    if (cardBrand === "AMEX" && cvv.length === 4) {
        valid = true;
    }
    if (cardBrand !== "AMEX" && cvv.length === 3) {
        valid = true;
    }

    return valid;
}


export function validateCardHolderName(e) {
    const theEvent = e;
    // Handle key press
    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    if(isNumeric(key)){
        e.preventDefault()
    }
}