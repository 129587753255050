// spaces length also included [4,8+1,12+2]
export const defaultSpaces = [4, 9, 14]

export const cardPatterns = [
    {
        pattern: /^(5018|5081|5044|504681|504993|5020|502260|5038|603845|603123|6304|6759|676[1-3]|6220|504834|504817|504645|504775|600206)/,
        brand: "Maestro",
        luhn: true,
        spaces: defaultSpaces,
        length: 16,
        code: "MAES"
    },
    {
        pattern: /^(508227|508[5-9]|603741|60698[5-9]|60699|607[0-8]|6079[0-7]|60798[0-4]|60800[1-9]|6080[1-9]|608[1-4]|608500|6521[5-9]|652[2-9]|6530|6531[0-4]|817290|817368|817378|353800)/,
        brand: "Rupay",
        luhn: true,
        spaces: defaultSpaces,
        length: 16,
        code: "RPAY"
    },
    {
        pattern: /^(36|38|30[0-5])/,
        brand: "Diners Club",
        luhn: true,
        spaces: [4, 11],
        length: 14,
        code: "DINR"
    },
    {
        pattern: /^(6011|65|64[4-9]|622)/,
        brand: "Discover",
        luhn: true,
        spaces: defaultSpaces,
        length: 16
    },
    {
        pattern: /^5[1-5]/,
        brand: "Mastercard",
        luhn: true,
        spaces: defaultSpaces,
        length: 16,
        code: "MC"
    },
    {
        pattern: /^3[47]/,
        brand: "AMEX",
        luhn: true,
        spaces: [4, 11],
        length: 15,
        code: "AMEX"
    },
    {
        pattern: /^4/,
        brand: "Visa",
        luhn: true,
        spaces: defaultSpaces,
        length: 16,
        code: "VI"
    },
    {
        pattern: /^(637513)/,
        brand: "Sodexo",
        luhn: true,
        spaces: defaultSpaces,
        length: 16
    },
    {
        pattern: /^(203040)/,
        brand: "Bajaj",
        luhn: true,
        spaces: defaultSpaces,
        length: 16
    }
];



export const TIMEOUT_SEC = 10;

export const paymentType = {
    CARD: "CARD"
}

//
export const PROD_LINK = "spg-prod-cdn.freecharge.in"

