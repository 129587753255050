import CardInputValidation from "./events/card";
import { paymentType } from './utils/constant';
import checkoutFc from './checkout';



const FCSetUp = {
    SetPay: ({ paymentForm,
        type,
        successHandler,
        errorHandler,
        inputCallback,
        enviroment }) => {

        // input callback handler
        if (inputCallback && paymentForm && type === paymentType.CARD) {
            CardInputValidation(paymentForm, inputCallback);
        }


        //submit form handler

        if (paymentForm) {

            const formNode = document.querySelector(`#${paymentForm}`);
            formNode.addEventListener('submit', (e) => {
                e.preventDefault()
                const data = new FormData(e.target);
                const formData = Object.fromEntries(data.entries());
                checkoutFc.submitCard(formData, formNode, errorHandler, enviroment);

            })
        }

    }
}


window.FC = FCSetUp;


