import { MERCHANT_CHECKOUT_PAY_URL, MERCHANT_CHECKOUT_PAY_URL_SANDBOX } from './api/endUrls';
import { AJAX } from "./api/ajax";
import { checkCardHolderName, getCardBrand, isValidCVV } from './utils/helper';
import { cardPatterns, PROD_LINK } from "./utils/constant";
import { validateCardNumber, checkCardExp } from "./utils/helper";

const checkoutFc = {
    submitCard: async function (formData, formNode, errorHandler, enviroment) {
        const cardBrand = getCardBrand(formData.cardNumber);
        const cardData = cardPatterns.find(card => card.brand === cardBrand);
        let txnSubMode = 'VI';
        if (cardData && cardData.code) {
            txnSubMode = cardData.code
        }


        // validate data
        const isCardValid = validateCardNumber(formData.cardNumber);
        const isNameValid = checkCardHolderName(formData.nameOnCard);
        const isValidExp = checkCardExp(formNode);
        const isValidMonth = +formData.cardExpMonth < 13 && +formData.cardExpMonth > 0
        const isValidCvv = isValidCVV(formData.cardCVV, cardBrand);

        const err = []
        if (!isCardValid || !isNameValid || !isValidExp || !isValidMonth || !isValidCvv) {

            if (!isValidExp || !isValidMonth) {
                err.push({ msg: "Invalid date" })
            }
            if (!isCardValid) {
                err.push({ msg: "Invalid card number" })
            }

            if (!isNameValid) {
                err.push({ msg: "Invalid card holder name" })
            }

            if (!isValidCvv) {
                err.push({ msg: "Invalid cvv" })
            }

            if (!formData.merchantId) {
                err.push({ msg: "Merchant Id is required" })
            }

            if (!formData.callbackUrl) {
                err.push({ msg: "Callback Url is required" })
            }

            if (!formData.callbackUrl) {
                err.push({ msg: "Callback Url is required" })
            }

            if (!formData.merchantTxnId) {
                err.push({ msg: "Merchant Txn Id is required" })
            }

            if (!formData.merchantTxnAmount) {
                err.push({ msg: "Merchant Txn Ammount is required" })
            }

            errorHandler(err);
            return;
        }

        // null check

        for (const key in formData) {
            if (formData[key] === null || formData[key] === 'null') {
                err.push({ msg: `${key} can not be null` });
                errorHandler(err);
                return;
            }
        }



        const values = {
            merchantId: formData.merchantId,
            callbackUrl: formData.callbackUrl,
            merchantTxnId: formData.merchantTxnId,
            merchantTxnAmount: parseFloat(formData.merchantTxnAmount),
            currency: "INR",
            txnMode: "DC",
            txnSubMode,
            cardHolderName: formData.nameOnCard,
            cardNumber: formData.cardNumber?.replaceAll(' ', ''),
            cardExpiryMonth: formData.cardExpMonth,
            cardExpiryYear: formData.cardExpYear,
            cardCvv: formData.cardCVV,
            timestamp: Date.now(),
            signature: formData.signature
        }
        // TODO remove before prod release


        if (enviroment !== 'PROD' && enviroment !== 'SANDBOX') {
            console.log("Wrong enviroment");
            return;
        }
        let checkoutUrl = MERCHANT_CHECKOUT_PAY_URL;

        if (enviroment === 'SANDBOX') {
            checkoutUrl = MERCHANT_CHECKOUT_PAY_URL_SANDBOX;
        }

        // console.log(values);
        this.submitForm(checkoutUrl, values);
    },
    submitForm: function (url, data) {
        var form = document.createElement("form");
        form.method = "POST";
        form.action = url;
        form.setAttribute('style', 'display:none;');
        form.id = 'frmPay';

        for (let key in data) {
            var element = document.createElement("input");
            element.value = data[key];
            element.name = key;
            form.appendChild(element);
        }
        document.body.appendChild(form);
        document.getElementById('frmPay').hidden = true;
        form.submit();
    }
};






export default checkoutFc;