import { validateCardNumber, getCardBrand, checkCardExp, isNumeric, hasNumber, validateNumInput, spaceSeparator, validateCardLen, isValidCVV, checkCardHolderName,validateCardHolderName } from "../utils/helper";


export class CardEvents {
    /**
     * 
     * @param {*} e 
     * @param {*} inputCallback 
     */

    static cardNumberResponse = (e, inputCallback) => {
        const cardNumber = e.target.value
        const response = {
            targetElement: "cardNumber",
            valid: validateCardNumber(cardNumber),
            cardBrand: getCardBrand(cardNumber)
        }

        inputCallback(response)
    }


    /**
 * 
 * @param {*} e 
 * @param {*} inputCallback 
 */

    // card holder name should not contain number in string
    static cardNameResponse = (e, inputCallback) => {
        const nameOnCard = e.target.value;
        const response = {
            targetElement: "nameOnCard",
            valid: checkCardHolderName(nameOnCard),
        }

        inputCallback(response)
    }



    /**
 * 
 * @param {*} e 
 * @param {*} inputCallback 
 */
    static cardMonthResponse = (e, inputCallback, formNode) => {
        const monthOnCard = parseInt(e.target.value);
        let valid = false;
        if (typeof monthOnCard === 'number' && monthOnCard > 0 && monthOnCard <= 12) {
            valid = true;
        }

        const response = {
            targetElement: "cardExpMonth",
            valid,
            validExp: checkCardExp(formNode)
        }

        inputCallback(response)
    }


    /**
     * 
     * @param {*} e 
     * @param {*} inputCallback 
     */
    static cardYearResponse = (e, inputCallback, formNode) => {
        const yearOnCard = parseInt(e.target.value);
        let valid = false;
        const currentFullYear = new Date().getFullYear();
        if (typeof yearOnCard === 'number' && yearOnCard >= currentFullYear) {
            valid = true;
        }

        const response = {
            targetElement: "cardExpYear",
            valid,
            validExp: checkCardExp(formNode)
        }

        inputCallback(response)
    }


    /**
     * 
     * @param {*} e 
     * @param {*} inputCallback 
     * @param {*} formNode 
     * @returns 
     */


    static cardCVVResponse = (e, inputCallback, formNode) => {
        const cvv = e.target.value;
        const cardNode = formNode.querySelector('.cardNumber');
        const cardNumber = cardNode.value;
        const cardBrand = getCardBrand(cardNumber);


        const response = {
            targetElement: "cardCVV",
            valid: isValidCVV(cvv, cardBrand),
        }
        inputCallback(response)
    }

}






/**
 * 
 * @param {*} paymentForm 
 * @param {*} inputCallback 
 */
const inputValidation = (paymentForm, inputCallback) => {

    // event listeners for input validation
    const formNode = document.querySelector(`#${paymentForm}`);

    //verify card number
    const cardNode = formNode.querySelector('.cardNumber');
    cardNode.addEventListener('input', (e) => CardEvents.cardNumberResponse(e, inputCallback));

    cardNode.addEventListener('keypress', validateCardLen)
    cardNode.addEventListener('keyup', spaceSeparator)
    cardNode.onpaste = e => e.preventDefault();


    //verify name on card
    const nameOnCard = formNode.querySelector('.nameOnCard');
    nameOnCard.addEventListener('input', (e) => CardEvents.cardNameResponse(e, inputCallback));

    nameOnCard.addEventListener('keypress', (e) => validateCardHolderName(e))
    nameOnCard.onpaste = e => e.preventDefault();

    //verify expiry date
    const cardExpYear = formNode.querySelector('.cardExpYear');
    const cardExpMonth = formNode.querySelector('.cardExpMonth');

    cardExpMonth.addEventListener('input', (e) => CardEvents.cardMonthResponse(e, inputCallback, formNode));
    cardExpYear.addEventListener('input', (e) => CardEvents.cardYearResponse(e, inputCallback, formNode));

    cardExpMonth.addEventListener('keypress', (e) => validateNumInput(e, 2))
    cardExpMonth.onpaste = e => e.preventDefault();

    cardExpYear.addEventListener('keypress', (e) => validateNumInput(e, 4))
    cardExpYear.onpaste = e => e.preventDefault();

    //verify cvv
    const cardCVV = formNode.querySelector('.cardCVV');
    cardCVV.addEventListener('input', (e) => CardEvents.cardCVVResponse(e, inputCallback, formNode));

    cardCVV.addEventListener('keypress', (e) => validateNumInput(e, 4))
    cardCVV.onpaste = e => e.preventDefault();

}





export default inputValidation;